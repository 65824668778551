import * as React from "react";
import About from "../components/About";
import Layout from "../components/Layout";
import Services from "../components/Services";
import Seo from "../components/Seo";
import BookingBlock from "../components/BookingBlock";
import News from "../components/News";

// markup
const IndexPage = () => {
  return (
    <Layout theme="light">
      <Seo />
      <About />
      <section>
        <h2>Neostrata verkkokauppa</h2>
        <p>
          Voit ostaa Neostrata -tuotteita myös verkkokaupastamme. Postikulut
          verkkokaupasta ovat 6,5€, ilmaiset yli 70€ tilauksille. Mikäli tilaat
          väh. neljä tuotetta, saat valita lahjan kaupan päälle.
        </p>
        <p>
          <a
            href="https://www.neostrata.fi/#clinic=kauneudenilo&amp;link=668328aa"
            className="button--outline"
            target="_blank"
            rel="noreferrer"
          >
            Neostrata verkkokauppa
          </a>
        </p>
      </section>
      <News />
      <BookingBlock />
      <Services />
    </Layout>
  );
};

export default IndexPage;
