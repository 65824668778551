import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useStaticQuery, graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const News = () => {
  const { allContentfulAjankohtaista: news } = useStaticQuery(graphql`
    {
      allContentfulAjankohtaista(sort: { order: DESC, fields: createdAt }) {
        edges {
          node {
            id
            header
            body {
              raw
            }
            createdAt(formatString: "DD.MM.Y")
          }
        }
      }
    }
  `);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <section className="news">
      <h2>Ajankohtaista</h2>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={6000}
        keyBoardControl={true}
        transitionDuration={500}
        itemClass="carousel-item"
      >
        {news?.edges?.length > 0 &&
          news.edges.map((single) => (
            <div className="carousel-card" key={single?.node?.id}>
              <h3>{single?.node?.header}</h3>
              {renderRichText(single?.node?.body)}
            </div>
          ))}
      </Carousel>
    </section>
  );
};

export default News;
