import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const About = () => {
  const { contentfulHoitolanKuvaus } = useStaticQuery(graphql`
    {
      contentfulHoitolanKuvaus {
        id
        header
        body {
          raw
        }
      }
    }
  `);

  return (
    <section className="about">
      <h2>{contentfulHoitolanKuvaus.header}</h2>
      {renderRichText(contentfulHoitolanKuvaus.body)}
    </section>
  );
};

export default About;
